import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Radio,
  Select,
} from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import TextArea from "antd/lib/input/TextArea";
import { allActions } from "../../Redux/myActions";
const { Option } = Select;

const UpdateSubscription = ({
  record,
  isUpdateSubscriptionModalVisible,
  setIsUpdateSubscriptionModalVisible,
  fetchActiveSubscriptions,
  fetchAllSubscriptions,
}) => {
  const [subscriptionData, setSubscriptionData] = useState({
    billingStartFrom: record.paymentWhen,
    packageName: record?.packageName,
    packagePrice: record?.packagePrice,
    packageDuration: record?.packageDuration,
    discount: record?.discount,
    description: record?.description,
    userCount: record?.userCount,
    deviceCount: record?.deviceCount,
    leadForms: record?.leadForms,
    leadGeneration: record?.leadGeneration,
    storage: record?.storage,
    storageInGb: record?.storageInGB,
    isActive: record?.isActive,
    isFree: record?.isFree,
    extraEachUserPrice: record?.pricePerUser,
    addOnPricePerGB: record?.addOnPricePerGB,
    subscriptionDuration: record?.subscriptionDuration,
    paymentWhen: record?.paymentWhen,
    scheduledDays: record?.scheduledDays,
  });
  const [subscriptionDuration, setSubscriptionDuration] = useState("CUSTOM");
  const [billingStartFrom, setBillingStartFrom] = useState("NOW");
  const [isFree, setIsFree] = useState(false);
  const actions = useDispatch();
  const [form] = Form.useForm();
  const UpdateSubscriptionSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
      isFree: value.packagePrice === 0 ? true : value.isFree,
      packagePrice: value.isFree ? 0 : value.packagePrice,
    };
    actions(
      allActions(
        { ...formData },
        {
          method: "put",
          endPoint: `/package/update/${record.id}`,
          attempt: "UPDATE_SUBSCRIPTIONS_REQUEST",
          success: "UPDATE_SUBSCRIPTIONS_REQUEST_SUCCESS",
          failure: "UPDATE_SUBSCRIPTIONS_REQUEST_FAILURE",
          successInternalState: (data) => {
            console.log("institute add response data", data);
            fetchActiveSubscriptions();
            fetchAllSubscriptions();
          },
          saveBearerToken: true,
        }
      )
    );
    form.resetFields();
    setIsUpdateSubscriptionModalVisible(false);
  };
  return (
    <Drawer
      title="Update Subscription"
      open={isUpdateSubscriptionModalVisible}
      onClose={() => {
        setIsUpdateSubscriptionModalVisible(false);
      }}
      footer={null}
      width={1160}
    >
      <Form
        layout="vertical"
        onFinish={UpdateSubscriptionSubmitHandler}
        colon={true}
        form={form}
        fields={[
          {
            name: ["billingStartFrom"],
            value: subscriptionData?.billingStartFrom,
          },
          { name: ["packageName"], value: subscriptionData?.packageName },
          { name: ["packagePrice"], value: subscriptionData?.packagePrice },
          {
            name: ["packageDuration"],
            value: subscriptionData?.packageDuration,
          },
          { name: ["discount"], value: subscriptionData?.discount },
          { name: ["description"], value: subscriptionData?.description },
          { name: ["userCount"], value: subscriptionData?.userCount },
          { name: ["deviceCount"], value: subscriptionData?.deviceCount },
          { name: ["leadForms"], value: subscriptionData?.leadForms },
          { name: ["leadGeneration"], value: subscriptionData?.leadGeneration },
          { name: ["storage"], value: subscriptionData?.storage },
          { name: ["storageInGb"], value: subscriptionData?.storageInGb },
          { name: ["isActive"], value: subscriptionData?.isActive },
          { name: ["isFree"], value: subscriptionData?.isFree },
          {
            name: ["extraEachUserPrice"],
            value: subscriptionData?.extraEachUserPrice,
          },
          {
            name: ["addOnPricePerGB"],
            value: subscriptionData?.addOnPricePerGB,
          },
          {
            name: ["subscriptionDuration"],
            value: subscriptionData?.subscriptionDuration,
          },
          { name: ["paymentWhen"], value: subscriptionData?.paymentWhen },
          { name: ["scheduledDays"], value: subscriptionData?.scheduledDays },
        ]}
        className="drawerStyle"
      >
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Package Name"
            name={"packageName"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter package name!",
              },
            ]}
          >
            <Input
              placeholder="Package Name"
              onChange={(e) => {
                setSubscriptionData((prevData) => {
                  return {
                    ...prevData,
                    packageName: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
          {subscriptionData.isFree === false && (
            <Form.Item
              label="Package Price"
              name={"packagePrice"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter package price!",
                },
              ]}
            >
              <Input
                type="number"
                placeholder="Package Price"
                onChange={(e) => {
                  setSubscriptionData((prevData) => {
                    return {
                      ...prevData,
                      packagePrice: e.target.value,
                    };
                  });
                }}
              />
            </Form.Item>
          )}
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Package Duration"
            name={"packageDuration"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter package duration",
              },
            ]}
          >
            <Select
              placeholder="Package Duration"
              onChange={(e) => {
                setSubscriptionData((prevData) => {
                  return {
                    ...prevData,
                    packageDuration: e,
                  };
                });
              }}
            >
              <Option value="10_DAYS">10 Days</Option>
              <Option value="15_DAYS">15 Days</Option>
              <Option value="1_MONTH">1 Month</Option>
              <Option value="3_MONTHS">3 Months</Option>
              <Option value="6_MONTHS">6 Months</Option>
              <Option value="1_YEAR">1 Year</Option>
              <Option value="2_YEAR">2 Years</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Discount (%)"
            name={"discount"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter discount",
              },
            ]}
          >
            <Input
              type="number"
              placeholder="Discount"
              onChange={(e) => {
                setSubscriptionData((prevData) => {
                  return {
                    ...prevData,
                    discount: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label={`Subscription Duration ${
              subscriptionData?.subscriptionDuration === "YEARLY"
                ? "(Plan Duration: 365 days)"
                : subscriptionData?.subscriptionDuration === "MONTHLY"
                ? "(Plan Duration: 30 Days)"
                : ""
            }`}
            name={"subscriptionDuration"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter subscription duration!",
              },
            ]}
          >
            <Select
              onChange={(e) => {
                setSubscriptionData((prevData) => {
                  return {
                    ...prevData,
                    subscriptionDuration: e,
                  };
                });
              }}
            >
              <Option value="CUSTOM">Custom</Option>
              <Option value="MONTHLY">Monthly</Option>
              <Option value="YEARLY">Yearly</Option>
            </Select>
          </Form.Item>

          {subscriptionData.subscriptionDuration === "CUSTOM" && (
            <Form.Item
              label="Plan Duration (in days)"
              name={"planDuration"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter plan duration!",
                },
              ]}
            >
              <Input
                type="number"
                placeholder="Plan Duration"
                onChange={(e) => {
                  setSubscriptionData((prevData) => {
                    return {
                      ...prevData,
                      planDuration: e.target.value,
                    };
                  });
                }}
              />
            </Form.Item>
          )}
        </div>

        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="User Count"
            name={"userCount"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter user count!",
              },
            ]}
          >
            <Input
              type="number"
              placeholder="User Count"
              onChange={(e) => {
                setSubscriptionData((prevData) => {
                  return {
                    ...prevData,
                    userCount: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
          <Form.Item
            label="Device Count"
            name={"deviceCount"}
            style={{ width: "100%" }}
          >
            <Input
              type="number"
              placeholder="Device Count"
              onChange={(e) => {
                setSubscriptionData((prevData) => {
                  return {
                    ...prevData,
                    deviceCount: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Lead Forms"
            name={"leadForms"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter lead forms!",
              },
            ]}
          >
            <Input
              type="number"
              placeholder="Lead Forms"
              onChange={(e) => {
                setSubscriptionData((prevData) => {
                  return {
                    ...prevData,
                    leadForms: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
          <Form.Item
            label="Lead Generation"
            name={"leadGeneration"}
            style={{ width: "100%" }}
          >
            <Input
              placeholder="Lead Generation"
              onChange={(e) => {
                setSubscriptionData((prevData) => {
                  return {
                    ...prevData,
                    leadGeneration: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
        </div>

        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Storage In GB"
            name={"storageInGb"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter Storage In GB!",
              },
            ]}
          >
            <Input
              placeholder="Storage In GB"
              onChange={(e) => {
                setSubscriptionData((prevData) => {
                  return {
                    ...prevData,
                    storageInGb: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
          <Form.Item
            name={"isFree"}
            valuePropName="checked"
            style={{ width: "100%" }}
          >
            <Checkbox
              onChange={(e) => {
                setSubscriptionData((prevData) => {
                  return {
                    ...prevData,
                    isFree: e.target.checked,
                  };
                });
              }}
            >
              Is Free?
            </Checkbox>
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Price Per User"
            name={"extraEachUserPrice"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter Price Per User!",
              },
            ]}
          >
            <Input
              placeholder="Price Per User"
              onChange={(e) => {
                setSubscriptionData((prevData) => {
                  return {
                    ...prevData,
                    extraEachUserPrice: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
          <Form.Item
            label="Addon Price Per GB"
            name={"addOnPricePerGB"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter Addon Price Per GB!",
              },
            ]}
          >
            <Input
              type="number"
              placeholder="Addon Price Per GB"
              onChange={(e) => {
                setSubscriptionData((prevData) => {
                  return {
                    ...prevData,
                    addOnPricePerGB: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Billing Start From"
            name={"paymentWhen"}
            style={{ width: "100%" }}
          >
            <Radio.Group
              onChange={(e) => {
                setSubscriptionData((prevData) => {
                  return {
                    ...prevData,
                    paymentWhen: e.target.value,
                  };
                });
              }}
            >
              <Radio value={"NOW"}>Now</Radio>
              <Radio value={"SCHEDULED"}>Scheduled</Radio>
            </Radio.Group>
          </Form.Item>
          {subscriptionData.paymentWhen === "SCHEDULED" && (
            <Form.Item
              label="Scheduled Days"
              name={"scheduledDays"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter scheduled days!",
                },
              ]}
            >
              <Input
                type="number"
                placeholder="Scheduled Days"
                onChange={(e) => {
                  setSubscriptionData((prevData) => {
                    return {
                      ...prevData,
                      scheduledDays: e.target.value,
                    };
                  });
                }}
              />
            </Form.Item>
          )}
        </div>
        <Form.Item
          label="Description"
          name={"description"}
          //   rules={[
          //     {
          //       required: true,
          //       message: "Please enter description!",
          //     },
          //   ]}
        >
          <TextArea
            placeholder="Write something about package."
            onChange={(e) => {
              setSubscriptionData((prevData) => {
                return {
                  ...prevData,
                  description: e.target.value,
                };
              });
            }}
          />
        </Form.Item>

        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Update Subscription
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default UpdateSubscription;
